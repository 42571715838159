import * as React from 'react'
import AboutHeader from '../components/about_header'
import Container from '../components/container'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const TeamPage = ({ data }) => {

  const team = data.contentfulPage

  return (
    <Layout>
      <Seo
        title={team.seoTitle || team.title}
        description={team.metaDescription || team.subtitle}
      />
      <AboutHeader
        title={team.title}
        subtitle={team.subtitle}
      />
      <div className='Section'>
        <Container size='lg'>
          <h2 className='text-xl sm:text-2xl text-center font-light mt-0 mb-4 sm:mb-5'>
            Leadership
          </h2>
          <div className='grid sm:grid-cols-2 gap-4 md:gap-y-5'>
            {team.items.map(member =>
              <div className='flex'>
                <div className='size-2of10 sm:size-3of10 md:size-fit'>
                  <GatsbyImage
                    image={member.headshot.gatsbyImageData}
                    imgClassName='border border-light-gray rounded-full'
                    alt=''
                  />
                </div>
                <div className='flex-1 pl-2 md:pl-3'>
                  <h3 className='font-regular text-lg sm:text-xl mt-0 mb-1 sm:font-light'>
                    {member.name}
                  </h3>
                  <p className='text-base font-semi-bold'>
                    {member.title}
                  </p>
                  <div className='leading-relaxed md:text-md'>
                    {renderRichText(member.bio)}
                    <ul className='list-reset mt-3'>
                      <li>
                        <b>First car:</b>
                        {' '}
                        {member.firstCar}
                      </li>
                      <li>
                        <b>Current car:</b>
                        {' '}
                        {member.currentCar}
                      </li>
                      <li>
                        <b>Dream car:</b>
                        {' '}
                        {member.dreamCar}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default TeamPage

export const pageQuery = graphql`
  query {
    contentfulPage(url: {eq: "team"}) {
      title
      seoTitle
      metaDescription
      subtitle
      items {
        ... on ContentfulTeamMember {
          name
          bio {
            raw
          }
          currentCar
          dreamCar
          firstCar
          headshot {
            gatsbyImageData(placeholder: BLURRED, width: 140)
          }
          title
        }
      }
    }
  }
`
